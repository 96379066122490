import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { TriggerClient } from '@cogent/client/shared/models/common/trigger-client.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { ObjectQueue } from '@cogent/client/shared/models/object-queue.model';
import { Questionnaire } from '@cogent/client/shared/models/common/questionnaire';
import { ObjectCommand } from '@cogent/client/shared/models/object-command.model';
import { CustomForm } from '@cogent/client/shared/models/custom-form.model';
import { DepartmentHomePageConfiguration } from '@cogent/client/shared/models/department-home-page-configuration.model';
import { ContractorSwimLane } from '@cogent/client/shared/models/service/contractor-swim-lane.model';
import { ObjectQueuesBoard } from '@cogent/client/shared/models/common/object-queues-board.model';
import { GlobalCommandClient } from '@cogent/client/shared/models/global-command-client.model';
import { RouteDefinitions } from '@upkeeplabs/cogent/app/all-routes';
import { SalesItemClient } from '@cogent/client/shared/models/items/sales-item-client.model';
import { RealEstatePortalResourceClient, RealEstatePortalResourceContainer } from '@cogent/shared/models/sales/real-estate-portal-resource-client.model';

import {
    AuthorizationRule, BIReport, BIReportGroup, Brand, ContractorTier, CustomFunction, Dictionary, DynamicAuthorizationAmountRule, DynamicServiceFeeRule, Entity, Holiday, Item, MonitoredEmail, PartMarkupAllowance,
    Permission,
    PurchaseOrderStatus,
    QueryableSchemaObject, Role, RolePermission, SecurityFilter, Singleton, Tag, TagMetrics, TaskQueue, Trade, TradeCategory, WorkOrderItem, WorkOrderItemCause, WorkOrderItemExcludedProductArea, WorkOrderItemLocation, WorkOrderItemProblem, WorkOrderItemProblemRepairItem, WorkOrderItemProblemRepairItemSelector, WorkOrderItemProblemType, WorkOrderItemType, WorkOrderStatus, WorkOrderTransferReason, Workflow
} from '@upkeeplabs/models/cogent'
import { WorkOrderItemClient } from '@cogent/client/shared/models/items/work-order-item-client.model';
import { SalesItemCoverageClient } from '@cogent/client/shared/models/plans/sales-item-coverage-client.model';
import { CustomerCareWallBoardConfig, WallBoardItem } from '@cogent/shared/models/service/customer-care-wall-board-config.model';
@Injectable({ providedIn: 'root' })
export class SettingsApiService {
    getWorkOrderItemById(id: string) {
        return this.api.getSingleNode(`WorkOrderItem/${id}`, null, () => new WorkOrderItem());
    }
    getUserRoles() {
        return this.api.getArrayNode('Role', { orderby: 'name' }, () => new Role());
    }

    private cachedObjects: any = {};

    constructor(private api: ApiService, private entityApi: EntityApiService) { }

    public getRealEstatePortalBaseUrl() {
        return this.api.getSingleDotNet(`features/real-estate-portal-base-url`);
    }

    public getCustomerPortalBaseUrl() {

        return this.api.getSingleDotNet(`features/customer-portal-base-url`);
    }

    saveLandingPageBackground(id: string, base64: string) {
        return this.api.postSingleNode(`document/save-landing-page/background/${id}`, { file: base64 });
    }

    getWorkOrderItemProductAreas(workOrderItemId: string) {
        return this.api.getArrayNode(`WorkOrderItemExcludedProductArea`, { workOrderItemId_eq: workOrderItemId }, () => new WorkOrderItemExcludedProductArea());
    }

    deleteWorkOrderItemExcludedProductArea(id: string) {
        return this.api.deleteNode(`WorkOrderItemExcludedProductArea/${id}`);
    }

    saveWorkOrderItemExcludedProductArea(excludedArea: WorkOrderItemExcludedProductArea) {
        return this.api.postNode(`WorkOrderItemExcludedProductArea`, excludedArea);
    }

    getContractorTiers() {
        return this.api.getArrayNode(`ContractorTier`, { orderby: 'tierName' }, () => new ContractorTier());
    }

    deleteSearchRecord(id: string) {
        return this.api.deleteDotNet('Search/' + id);
    }

    async getCCWallBoardConfig(): Promise<CustomerCareWallBoardConfig> {
        const dictionary = await this.getDictionaryByKey('cc-wall-board');

        if (dictionary) {
            const config = UtilitiesService.copyObject(JSON.parse(dictionary.value), null, () => new CustomerCareWallBoardConfig());
            config.items = UtilitiesService.copyArrayToTypedArray(config.items, () => new WallBoardItem());
            return config;
        }
        return new CustomerCareWallBoardConfig();
    }

    async getAuthoWallBoardConfig(): Promise<CustomerCareWallBoardConfig> {
        const dictionary = await this.getDictionaryByKey('autho-wall-board');

        if (dictionary) {
            const config = UtilitiesService.copyObject(JSON.parse(dictionary.value), null, () => new CustomerCareWallBoardConfig());
            config.items = UtilitiesService.copyArrayToTypedArray(config.items, () => new WallBoardItem());
            return config;
        }
        return new CustomerCareWallBoardConfig();
    }

    async saveDictionaryValue(key: string, value: any) {
        let dictionary = await this.getDictionaryByKey(key);

        if (!dictionary) {
            dictionary = new Dictionary();
            dictionary.itemKey = key;
            dictionary.id = UtilitiesService.newid();
        }

        dictionary.value = JSON.stringify(value);
        console.log(dictionary);

        this.api.postSingleNode(`dictionary`, dictionary);

    }

    getWorkOrderItemAttributes(workOrderItemId: string) {
        return this.api.getArrayDotNet(`purchasing/purchaseorder/${workOrderItemId}/get-attributes`);
    }

    getSMSPhoneNumbers() {
        return this.api.getArrayDotNet(`twilio/get-numbers`);
    }

    getQueryableSchema(type: string): Promise<QueryableSchemaObject[]> {
        return this.api.getArrayNode(`QueryableSchemaObject`, { type_eq: type }, () => new QueryableSchemaObject());
    }

    getPartsMarkupAllowances(): Promise<PartMarkupAllowance[]> {
        return this.api.getArrayNode(`PartMarkupAllowance`, { orderby: 'maxDollarAmount' }, () => new PartMarkupAllowance());
    }

    async saveMarkupAllowances(recordsToSave: PartMarkupAllowance[], recordsToDelete: PartMarkupAllowance[]) {
        const promises = [];

        for (const record of recordsToSave) {
            promises.push(this.api.postNode(`PartMarkupAllowance`, record));
        }
        await Promise.all(promises);
        return true;
    }

    getQueryableSchemaTablesAndView(): Promise<QueryableSchemaObject[]> {
        return this.api.getArrayNode(`QueryableSchemaObject`, { type_neq: 'Column', orderby: 'name' }, () => new QueryableSchemaObject());
    }

    getQueryableSchemaColumns(objectId: number): Promise<QueryableSchemaObject[]> {
        return this.api.getArrayNode(`QueryableSchemaObject`, { type_eq: 'Column', object_id_eq: objectId }, () => new QueryableSchemaObject());
    }

    getNodeSMSNumbers() {
        return this.api.getArrayDotNet(`twilio/get-node-numbers`);
    }

    public getWorkOrderItemsByTrade(tradeId: string): Promise<WorkOrderItemClient[]> {
        return this.api.getArrayDotNet(`WorkOrderItem`, { tradeId_eq: tradeId, orderby: 'name' }, () => new WorkOrderItemClient());
    }

    public getWorkOrderItems(): Promise<WorkOrderItemClient[]> {
        return this.api.getArrayDotNet(`WorkOrderItem`, { orderby: 'name' }, () => new WorkOrderItemClient());
    }

    public getContractorPortalBaseUrl() {
        return this.api.getSingleDotNet(`features/contractor-portal-base-url`);
    }

    getDynamicServiceFeeRules(): Promise<DynamicServiceFeeRule[]> {
        return this.api.getArrayNode(`DynamicServiceFeeRule`, null, () => new DynamicServiceFeeRule());
    }

    updateDynamiceServiceFeeRuleSort(dynamicServiceFee: DynamicServiceFeeRule) {
        return this.api.patchSingleNode(`DynamicServiceFeeRule/${dynamicServiceFee.id}`, { sort: dynamicServiceFee.sort });
    }

    getTrade(id: string): Promise<Trade> {
        return this.api.getSingleDotNet(`Trade`, { id_eq: id }, () => new Trade());
    }

    getTrade2(id: string): Promise<Trade> {
        return this.api.getSingleNode(`Trade`, { id_eq: id }, () => new Trade());
    }

    saveDynamicServiceFee(dynamicServiceFee: DynamicServiceFeeRule) {
        return this.api.postSingleNode(`DynamicServiceFeeRule`, dynamicServiceFee);
    }

    deleteDynamicServiceFee(dynamicServiceFee: DynamicServiceFeeRule) {
        return this.api.deleteNode(`DynamicServiceFeeRule/${dynamicServiceFee.id}`);
    }

    unDeleteDynamicSerivceFee(dynamicServiceFee: DynamicServiceFeeRule) {
        return this.api.unDeleteNode(`DynamicServiceFeeRule/${dynamicServiceFee.id}`);
    }



    getDynamicAuthorizationAmountRules(): Promise<DynamicAuthorizationAmountRule[]> {
        return this.api.getArrayDotNet(`DynamicAuthorizationAmountRule`, null, () => new DynamicAuthorizationAmountRule());
    }

    updateDynamicAuthorizationAmountSort(dynamicAuthorizationAmountRule: DynamicAuthorizationAmountRule) {
        return this.api.patchSingleDotNet(`DynamicAuthorizationAmountRule/${dynamicAuthorizationAmountRule.id}`, { sort: dynamicAuthorizationAmountRule.sort });
    }

    saveDynamicAuthorizationAmountRule(dynamicAuthorizationAmountRule: DynamicAuthorizationAmountRule) {
        return this.api.postSingleDotNet(`DynamicAuthorizationAmountRule`, dynamicAuthorizationAmountRule);
    }

    deleteDynamicAuthorizationAmountRule(dynamicAuthorizationAmountRule: DynamicAuthorizationAmountRule) {
        return this.api.deleteDotNet(`DynamicAuthorizationAmountRule/${dynamicAuthorizationAmountRule.id}`);
    }

    unDeleteDynamicAuthorizationAmountRule(dynamicAuthorizationAmountRule: DynamicAuthorizationAmountRule) {
        return this.api.unDeleteDotNet(`DynamicAuthorizationAmountRule/${dynamicAuthorizationAmountRule.id}`);
    }


    public getTradeCategories(): Promise<TradeCategory[]> {
        return this.api.getArrayDotNet('TradeCategory', { orderby: 'sort' }, () => new TradeCategory());
    }

    public getTradeCategories2(): Promise<TradeCategory[]> {
        return this.api.getArrayNode('TradeCategory', { orderby: 'sort' }, () => new TradeCategory());
    }

    getContractorSwimLanes(): Promise<ContractorSwimLane[]> {
        return this.api.getArrayDotNet(`ContractorSwimLane`, { orderby: 'Sort,Name' }, () => new ContractorSwimLane());
    }

    getMonitoredEmailInboxes(): Promise<MonitoredEmail[]> {
        return this.api.getArrayDotNet(`MonitoredEmail`, { orderby: 'Name' }, () => new MonitoredEmail());
    }

    getTriggerList(): Promise<TriggerClient[]> {
        return this.api.getArrayDotNet(`TriggerSummary`, { select: 'id,name,type', orderby: 'name' }, () => new TriggerClient());
    }

    getTriggerSummary(ids: string[]) {
        return this.api.getArrayDotNet(`TriggerSummary`, { id_contains: this.api.flattenStringArguments(ids), orderby: 'name' });
    }

    saveMonitoredEmailInbox(monitoredEmail: MonitoredEmail) {
        return this.api.postSingleDotNet('MonitoredEmail', monitoredEmail);
    }

    deleteMonitoredEmail(id: string) {
        return this.api.deleteDotNet(`MonitoredEmail/${id}`);
    }

    unDeleteMonitoredEmail(id: string) {
        return this.api.unDeleteDotNet(`MonitoredEmail/${id}`);
    }

    getRealEstatePortalResources(): Promise<RealEstatePortalResourceClient[]> {
        return this.api.getArrayDotNet(`RealEstatePortalResource`, { orderby: 'Sort,DocumentName' }, () => new RealEstatePortalResourceClient());
    }

    getRealEstatePortalResourcesUnassigned(): Promise<RealEstatePortalResourceClient[]> {
        return this.api.getArrayDotNet(`RealEstatePortalResource`, { SalesRepEntityId_eq: '{{null}}', orderby: 'Sort,DocumentName' }, () => new RealEstatePortalResourceClient());
    }

    getRealEstatePortalResourcesForSalesRep(salesRepId: string): Promise<RealEstatePortalResourceClient[]> {
        return this.api.getArrayDotNet(`RealEstatePortalResource`, { SalesRepEntityId_eq: salesRepId, orderby: 'Sort,DocumentName' }, () => new RealEstatePortalResourceClient());
    }

    saveRealEstatePortalResource(container: RealEstatePortalResourceContainer) {
        return this.api.postSingleDotNet(`RealEstatePortalResource/upload`, container);
    }

    saveJustRealEstatePortalResource(resource: RealEstatePortalResourceClient) {
        return this.api.postSingleDotNet(`RealEstatePortalResource`, resource);
    }

    deleteRealEstatePortalResource(id: string) {
        return this.api.deleteDotNet(`RealEstatePortalResource/${id}`);
    }

    unDeleteRealEstatePortalResource(id: string) {
        return this.api.unDeleteDotNet(`RealEstatePortalResource/${id}`);
    }


    public saveWorkOrderItem(workOrderItem: WorkOrderItemClient): Promise<string> {
        return this.api.postIdDotNet('WorkOrderItem', workOrderItem);
    }
    public saveWorkOrderItemLocation(workOrderItemLocation: WorkOrderItemLocation): Promise<string> {
        return this.api.postIdDotNet('WorkOrderItemLocation', workOrderItemLocation);
    }
    public saveWorkOrderItemType(workOrderItemType: WorkOrderItemType): Promise<string> {
        return this.api.postIdNode('WorkOrderItemType', workOrderItemType);
    }
    public saveWorkOrderItemCause(workOrderItemCause: WorkOrderItemCause): Promise<string> {
        return this.api.postIdDotNet('WorkOrderItemCause', workOrderItemCause);
    }

    public async saveWorkOrderItemProblem(workOrderItemProblem: WorkOrderItemProblem): Promise<string> {
        const result = await this.api.postIdDotNet('WorkOrderItemProblem', workOrderItemProblem);
        if (!workOrderItemProblem.repairItems) {
            workOrderItemProblem.repairItems = [];
        }

        await this.saveProblemRepairItems(workOrderItemProblem, workOrderItemProblem.selectedRepairItems, false);
        await this.saveProblemRepairItems(workOrderItemProblem, workOrderItemProblem.selectedReplacementItems, true);

        if (workOrderItemProblem.selectedTypes.length > 0) {
            for (const type of workOrderItemProblem.selectedTypes) {

                if (!workOrderItemProblem.types) {
                    workOrderItemProblem.types = [];
                }

                const exists = workOrderItemProblem.types.find(t => t.workOrderItemTypeId === type.workOrderItemTypeId);
                if (type.isChecked) {
                    if (!exists) {
                        const newType = new WorkOrderItemProblemType();
                        newType.id = UtilitiesService.newid();
                        newType.workOrderItemProblemId = workOrderItemProblem.id;
                        newType.workOrderItemTypeId = type.workOrderItemTypeId;
                        await this.saveWorkOrderItemProblemType(newType);
                        workOrderItemProblem.types.push(newType);
                    }
                } else {
                    if (exists) {
                        await this.deleteWorkOrderItemProblemType(exists);
                    }
                }
            }
        }

        return result;
    }

    private async saveProblemRepairItems(workOrderItemProblem: WorkOrderItemProblem, items: WorkOrderItemProblemRepairItemSelector[], isReplacement: boolean) {
        if (items.length > 0) {
            for (const item of items) {
                let exists = workOrderItemProblem.repairItems.find(ri => ri.repairItemId === item.repairItemId && ri.isReplacement === isReplacement);
                if (item.isChecked) {
                    let changed = false;
                    if (!exists) {
                        changed = true;
                        const newItem = new WorkOrderItemProblemRepairItem();
                        newItem.id = UtilitiesService.newid();
                        newItem.workOrderItemProblemId = workOrderItemProblem.id;
                        newItem.repairItemId = item.repairItemId;
                        newItem.isReplacement = isReplacement;
                        workOrderItemProblem.repairItems.push(newItem);
                        exists = newItem;
                    }
                    let types = [];
                    let allChecked = true;
                    for (const type of item.types) {
                        if (type.isChecked) {
                            types.push(type.workOrderItemTypeId);
                        } else {
                            allChecked = false;
                        }
                    }
                    let typesJson = allChecked ? null : JSON.stringify(types);
                    if (exists.workOrderItemTypes != typesJson) {
                        changed = true;
                        exists.workOrderItemTypes = typesJson;
                    }
                    if (changed) {
                        await this.saveWorkOrderItemProblemRepairItem(exists);
                    }
                } else {
                    if (exists) {
                        await this.deleteWorkOrderItemProblemRepairItem(exists);
                    }
                }
            }
        }
    }

    public saveWorkOrderItemProblemRepairItem(repairItem: WorkOrderItemProblemRepairItem) {
        return this.api.postIdDotNet("WorkOrderItemProblemRepairItem", repairItem);
    }

    public saveWorkOrderItemProblemType(type: WorkOrderItemProblemType) {
        return this.api.postIdDotNet("WorkOrderItemProblemType", type);
    }

    public deleteWorkOrderItemProblemRepairItem(repairItem: WorkOrderItemProblemRepairItem) {
        return this.api.deleteDotNet("WorkOrderItemProblemRepairItem/" + repairItem.id);
    }
    public deleteWorkOrderItemProblemType(type: WorkOrderItemProblemType) {
        return this.api.deleteDotNet("WorkOrderItemProblemType/" + type.id);
    }

    public deleteWorkOrderItemLocation(workOrderItemLocation: WorkOrderItemLocation): Promise<string> {
        return this.api.deleteDotNet('WorkOrderItemLocation/' + workOrderItemLocation.id);
    }
    public deleteWorkOrderItemType(workOrderItemType: WorkOrderItemType): Promise<string> {
        return this.api.deleteDotNet('WorkOrderItemType/' + workOrderItemType.id);
    }
    public unDeleteWorkOrderItemType(workOrderItemType: WorkOrderItemType) {
        return this.api.unDeleteNode('WorkOrderItemType/' + workOrderItemType.id);
    }
    public deleteWorkOrderItemProblem(workOrderItemProblem: WorkOrderItemProblem): Promise<string> {
        return this.api.deleteDotNet('WorkOrderItemProblem/' + workOrderItemProblem.id);
    }
    public deleteWorkOrderItemCause(workOrderItemCause: WorkOrderItemCause): Promise<string> {
        return this.api.deleteDotNet('WorkOrderItemCause/' + workOrderItemCause.id);
    }

    findClosingItems(searchFor: string): Promise<Item[]> {
        return this.api.getArrayDotNet(`item/find`, { searchFor, orderby: 'number' }, () => new Item());
    }

    async getPlanStyle(planId: string) {
        const planStuff = await this.api.getSingleDotNet(`plan/${planId}`, { select: 'planStyleId' });
        if (planStuff && planStuff.planStyleId) {
            return this.api.getSingleNode(`PlanStyle/${planStuff.planStyleId}`);
        } else {
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    }

    getBIReportGroups(): Promise<BIReportGroup[]> {
        return this.api.getArrayNode(`BIReportGroup`, { orderby: 'sort' }, () => new BIReportGroup());
    }

    getBIReports(): Promise<BIReport[]> {
        return this.api.getArrayNode(`BIReport`, { orderby: 'biReportGroupId,sort' }, () => new BIReport());
    }

    addBIReportGroup(group: BIReportGroup) {
        if (!group.id)
            group.id = UtilitiesService.newid();

        return this.api.postSingleNode(`BIReportGroup`, group);
    }

    addBIReport(report: BIReport) {
        if (!report.id) {
            report.id = UtilitiesService.newid();
        }
        return this.api.postSingleNode(`BIReport`, report);
    }

    deleteBIReportGroup(group: BIReportGroup) {
        return this.api.deleteNode(`BIReportGroup/${group.id}`);
    }

    deleteBIReport(report: BIReport) {
        return this.api.deleteNode(`BIReport/${report.id}`);
    }

    undoDeleteBIReportGroup(group: BIReportGroup) {
        return this.api.unDeleteNode(`BIReportGroup/${group.id}`);
    }

    undoDeleteBIReport(report: BIReport) {
        return this.api.unDeleteNode(`BIReport/${report.id}`);
    }

    getTaskQueues(): Promise<TaskQueue[]> {
        return this.api.getArrayDotNet('TaskQueue', { select: 'id,name', orderby: 'name' }, () => new TaskQueue());
    }

    saveTaskQueue(taskQueue: TaskQueue) {
        return this.api.postSingleDotNet('TaskQueue', taskQueue);
    }

    deleteTaskQueue(taskQueue: TaskQueue) {
        return this.api.deleteDotNet(`TaskQueue/${taskQueue.id}`);
    }

    unDeleteTaskQueue(taskQueue: TaskQueue) {
        return this.api.unDeleteDotNet(`TaskQueue/${taskQueue.id}`);
    }

    renameTaskQueue(id: string, name: string) {
        return this.api.patchSingleDotNet(`TaskQueue/${id}`, { name });
    }

    deleteSalesItemCoverage(salesItemCoverage: SalesItemCoverageClient) {
        return this.api.deleteDotNet(`SalesItemCoverage/${salesItemCoverage.id}`);
    }

    getSingleton(): Promise<Singleton> {
        return this.api.getSingleDotNet('Singleton', null, () => new Singleton());
    }

    getSingletonNode(): Promise<Singleton> {
        return this.api.getSingleNode('Singleton', null, () => new Singleton());
    }

    saveSingleton(singleton: Singleton) {
        delete singleton.nextPolicyNumber;
        return this.api.patchSingleDotNet(`Singleton/${singleton.id}`, singleton);
    }

    unDeleteSalesItemCoverage(salesItemCoverage: SalesItemCoverageClient) {
        return this.api.unDeleteDotNet(`SalesItemCoverage/${salesItemCoverage.id}`);
    }

    deleteWorkOrderItem(workOrderItemId: string) {
        return this.api.deleteDotNet(`WorkOrderItem/${workOrderItemId}`);
    }

    unDeleteWorkOrderItem(workOrderItemId: string) {
        return this.api.unDeleteDotNet(`WorkOrderItem/${workOrderItemId}`);
    }

    public getUseIntegratedPhoneSystem(): Promise<boolean> {
        return this.api.getSingleDotNet('features/integrated-phone-system');
    }

    public resetApiCache(): Promise<boolean> {
        return this.api.patchSingleDotNet(`features/reset-features-cache`, null);
    }

    public syncMenuItems() {
        return this.api.patchSingleDotNet('features/sync-angular-routes', RouteDefinitions.getAllRouteDefinitions());
    }

    public resetSearchIndex() {
        return this.api.patchDotNet(`search/reset-search-index`, null);
    }

    getCustomForms(): Promise<CustomForm[]> {
        return this.api.getArrayDotNet(`CustomForm`, { orderby: 'name' }, () => new CustomForm());
    }

    deleteCustomForm(id: string) {
        return this.api.deleteDotNet(`CustomForm/${id}`);
    }

    unDeleteCustomForm(id: string) {
        return this.api.unDeleteDotNet(`CustomForm/${id}`);
    }

    saveCustomForm(form: CustomForm) {
        if (form.formItems) {
            for (const item of form.formItems) {
                delete item.answer;
            }
        } else {
            form.formItems = [];
        }
        form.form = JSON.stringify(form.formItems);
        return this.api.postSingleDotNet(`CustomForm`, form);
    }

    async getDepartmentHomePageConfigurationCached(key: string): Promise<DepartmentHomePageConfiguration> {

        if (!this.cachedObjects[key]) {
            this.cachedObjects[key] = await this.getDepartmentHomePageConfiguration(key);
        }

        return this.cachedObjects[key];
    }

    getDepartmentHomePageConfiguration(key: string): Promise<DepartmentHomePageConfiguration> {
        return this.api.getSingleDotNet(`DepartmentHomePageConfiguration`, { pageKey_eq: key }, () => new DepartmentHomePageConfiguration());
    }

    saveDepartmentHomePageConfiguration(configuration: DepartmentHomePageConfiguration) {
        this.cachedObjects[configuration.pageKey] = configuration;
        configuration.configuration = JSON.stringify(configuration.configurationObject);
        return this.api.postSingleDotNet('DepartmentHomePageConfiguration', configuration);
    }

    getAllTrades(): Promise<Trade[]> {
        return this.api.getArrayNode('trade', { orderby: 'name' }, () => new Trade());
    }

    saveTrade(trade: Trade) {
        return this.api.postSingleNode('Trade', trade);
    }

    saveTradeCategory(tradeCategory: TradeCategory) {
        return this.api.postSingleNode('TradeCategory', tradeCategory);
    }

    deleteTrade(tradeId: string) {
        return this.api.deleteNode(`Trade/${tradeId}`);
    }

    unDeleteTrade(tradeId: string) {
        return this.api.unDeleteNode(`Trade/${tradeId}`);
    }

    deleteTradeCategory(tradeCategoryId: string) {
        return this.api.deleteNode(`TradeCategory/${tradeCategoryId}`);
    }

    unDeleteTradeCategory(tradeCategoryId: string) {
        return this.api.unDeleteNode(`TradeCategory/${tradeCategoryId}`);
    }

    getAllPermissions(): Promise<Permission[]> {
        return this.api.getArrayDotNet('Permission', { orderby: 'name' }, () => new Permission());
    }

    savePermission(permission: Permission) {
        return this.api.postSingleDotNet(`Permission`, permission);
    }

    deletePermission(permissionId: string) {
        return this.api.deleteDotNet(`Permission/${permissionId}`);
    }

    unDeletePermission(permissionId: string) {
        return this.api.unDeleteDotNet(`Permission/${permissionId}`);
    }

    getObjectCommands(): Promise<ObjectCommand[]> {
        return this.api.getArrayDotNet('ObjectCommand', { orderby: 'name' }, () => new ObjectCommand());
    }

    getObjectCommandById(id: string): Promise<ObjectCommand> {
        return this.api.getSingleDotNet(`ObjectCommand/${id}`, null, () => new ObjectCommand());
    }

    getObjectCommandsByType(type: string,): Promise<ObjectCommand[]> {
        const parameters: any = { commandType_eq: type, orderby: 'name', availableOnCogent_eq: true };

        return this.api.getArrayDotNet('ObjectCommand', parameters, () => new ObjectCommand());
    }

    getJobDetailCommands(): Promise<ObjectCommand[]> {
        return this.api.getArrayDotNet('ObjectCommand', {
            commandType_eq: 'Service',
            OrderBy: 'name',
            AvailableOnJobDetail_eq: true,
            AvailableOnCogent_eq: true
        }, () => new ObjectCommand());
    }

    getAuthorizationCommandsCogent(): Promise<ObjectCommand[]> {
        return this.api.getArrayDotNet('ObjectCommand', {
            commandType_eq: 'Service',
            OrderBy: 'name',
            AvailableOnAuthorization_eq: true,
            AvailableOnCogent_eq: true
        }, () => new ObjectCommand());
    }

    getAuthorizationTaskCommandsCogent(): Promise<ObjectCommand[]> {
        return this.api.getArrayDotNet('ObjectCommand', {
            commandType_eq: 'Task',
            OrderBy: 'name',
            AvailableOnAuthorization_eq: true,
            AvailableOnCogent_eq: true
        }, () => new ObjectCommand());
    }

    getQuestionnaireCommandsByType(type: string = null): Promise<ObjectCommand[]> {
        const parameters: any = { orderby: 'name', AvailableToQuestionnaires_eq: true };
        if (type) {
            parameters.commandType_eq = type;
        }

        return this.api.getArrayDotNet('ObjectCommand', parameters, () => new ObjectCommand());
    }

    getObjectCommandsForCustomerPortal(type: string) {
        const parameters: any = { commandType_eq: type, orderby: 'name', availableOnCustomerPortal_eq: true };

        return this.api.getArrayDotNet('ObjectCommand', parameters, () => new ObjectCommand());
    }

    getCustomFunctionsForCustomerPortalJob() {
        return this.api.getArrayNode(`CustomFunction`, { AvailableOnCustomerPortalJob_eq: true }, () => new CustomFunction());
    }

    getObjectCommandsForContractorPortal(type: string) {
        const parameters: any = { commandType_eq: type, orderby: 'name', availableOnContractorPortal_eq: true, availableOnJobDetail_eq: true };

        return this.api.getArrayDotNet('ObjectCommand', parameters, () => new ObjectCommand());
    }

    getObjectCommandsForJobReschedule() {
        return this.api.getArrayDotNet(`AnonymousClaimDetail/job-reschedule-commands`, null, () => new ObjectCommand());
    }


    updateWorkOrderItemPriority(id: string, priority: number) {
        return this.api.patchSingleDotNet(`WorkOrderItem/${id}`, { priority });
    }

    saveObjectCommand(objectCommand: ObjectCommand) {
        return this.api.postSingleDotNet('ObjectCommand', objectCommand);
    }

    deleteObjectCommand(id: string) {
        return this.api.deleteDotNet(`ObjectCommand/${id}`);
    }

    unDeleteCommand(id: string) {
        return this.api.unDeleteDotNet(`ObjectCommand/${id}`);
    }

    deleteObjectQueuesBoard(id: string) {
        return this.api.deleteDotNet(`ObjectQueuesBoard/${id}`);
    }

    unDeleteObjectQueuesBoard(id: string) {
        return this.api.unDeleteDotNet(`ObjectQueuesBoard/${id}`);
    }

    deleteObjectQueuesDistribution(id: string) {
        return this.api.deleteDotNet(`ObjectQueuesDistribution/${id}`);
    }

    unDeleteObjectQueuesDistribution(id: string) {
        return this.api.unDeleteDotNet(`ObjectQueuesDistribution/${id}`);
    }

    getDictionaryByKey(key: string) {
        return this.api.getSingleNode(`Dictionary`, { itemKey_eq: key }, () => new Dictionary());
    }

    saveDictionary(item: Dictionary) {
        return this.api.postNode('Dictionary', item);
    }

    getAllRoles(): Promise<Role[]> {
        return this.api.getArrayDotNet(`Role`, { orderby: 'name' }, () => new Role());
    }

    getSecurityFilters(): Promise<SecurityFilter[]> {
        return this.api.getArrayNode(`SecurityFilter`, { orderBy: 'objectType' }, () => new SecurityFilter());
    }

    getRolePermissions(role: Role): Promise<RolePermission[]> {
        return this.api.getArrayDotNet(`RolePermission`, { roleId_eq: role.id }, () => new RolePermission());
    }

    async saveRole(role: Role, rolePermissionsToSave: RolePermission[], rolePermissionsToDelete: RolePermission[]) {
        await this.api.postArrayDotNet(`Role`, role);
        const promises = [];


        if (rolePermissionsToSave) {
            rolePermissionsToSave.forEach(rp => promises.push(this.api.postSingleDotNet(`RolePermission`, rp)));
        }
        if (rolePermissionsToDelete) {
            rolePermissionsToDelete.forEach(rp => promises.push(this.api.deleteDotNet(`RolePermission/${rp.id}`)));
        }
        return Promise.all(promises);
    }

    deleteRole(id: string) {
        return this.api.deleteDotNet(`Role/${id}`);
    }

    unDeleteRole(id: string) {
        return this.api.unDeleteDotNet(`Role/${id}`);
    }

    getQuestionnaireSummary(): Promise<Questionnaire[]> {
        return this.api.getArrayDotNet(`Questionnaire`, { orderby: 'name', properties: 'id,name' }, () => new Questionnaire());
    }

    getWorkflows(): Promise<Workflow[]> {
        return this.api.getArrayDotNet('Workflow', { orderby: 'name', properties: 'id,name' }, () => new Workflow());
    }


    getCustomFormSummaries(): Promise<CustomForm[]> {
        return this.api.getArrayDotNet(`CustomForm`, { orderby: 'name', properties: 'id,name' }, () => new CustomForm());
    }

    getCustomFormById(id: string) {
        return this.api.getSingleDotNet(`CustomForm/${id}`, null, () => new CustomForm());
    }

    deleteQuestionnaire(id: string): Promise<any> {
        return this.api.deleteDotNet(`Questionnaire/${id}`);
    }

    unDeleteQuestionnaire(id: string): Promise<any> {
        return this.api.unDeleteDotNet(`Questionnaire/${id}`);
    }

    deleteWorkflow(id: string): Promise<any> {
        return this.api.deleteDotNet(`Workflow/${id}`);
    }

    unDeleteWorkflow(id: string): Promise<any> {
        return this.api.unDeleteDotNet(`Workflow/${id}`);
    }

    getQuestionnaireItem(id: string): Promise<Questionnaire> {
        return this.api.getSingleDotNet(`Questionnaire/${id}`, null, () => new Questionnaire());
    }

    getWorkflowItem(id: string): Promise<Workflow> {
        return this.api.getSingleDotNet(`Workflow/${id}`, null, () => new Workflow());
    }

    saveQuestionnaire(questionnaire: Questionnaire): Promise<any> {
        return this.api.postSingleDotNet(`Questionnaire`, questionnaire);
    }

    saveWorkflow(workflow: Workflow) {
        return this.api.postSingleDotNet(`Workflow`, workflow);
    }

    updateQuestionnaireProcess(id: string, process: string) {
        return this.api.patchDotNet(`Questionnaire/${id}`, { process });
    }

    updateWorkflowProcess(id: string, workflowDefinition: string) {
        return this.api.patchDotNet(`Workflow/${id}`, { workflowDefinition });
    }

    getHolidays(startDate: Date): Promise<Holiday[]> {
        return this.api.getArrayNode(`Holiday`, { holidayDate_gte: startDate, orderby: 'HolidayDate' });
    }



    getWorkOrderItemAuthoProcess(workOrderItemId: string): Promise<WorkOrderItemClient> {
        return this.api.getSingleDotNet(`WorkOrderItem/${workOrderItemId}`, { select: 'id,AuthoProcess' });
    }

    saveHoliday(holiday: Holiday) {
        return this.api.postSingleNode(`Holiday`, holiday);
    }

    savePurchaseOrderStatus(purchaseOrderStatus: PurchaseOrderStatus) {
        return this.api.postSingleDotNet(`PurchaseOrderStatus`, purchaseOrderStatus);
    }

    saveWorkOrderTransferReason(workOrderTransferReason: WorkOrderTransferReason) {
        return this.api.postSingleNode(`WorkOrderTransferReason`, workOrderTransferReason);
    }

    deletePurchaseOrderStatus(purchaseOrderStatusId: string) {
        return this.api.deleteDotNet(`PurchaseOrderStatus/${purchaseOrderStatusId}`);
    }

    deleteWorkOrderTransferReason(workOrderTransferReasonId: string) {
        return this.api.deleteNode(`WorkOrderTransferReason/${workOrderTransferReasonId}`);
    }

    unDeletePurchaseOrderStatus(purchaseOrderStatusId: string) {
        return this.api.unDeleteDotNet(`PurchaseOrderStatus/${purchaseOrderStatusId}`);
    }

    unDeleteWorkOrderTransferReason(workOrderTransferReasonId: string) {
        return this.api.unDeleteNode(`WorkOrderTransferReason/${workOrderTransferReasonId}`);
    }

    deleteHoliday(holidayId: string) {
        return this.api.deleteNode(`Holiday/${holidayId}`);
    }

    unDeleteHoliday(holidayId: string) {
        return this.api.unDeleteNode(`Holiday/${holidayId}`);
    }


    getWorkOrderStatuses(): Promise<WorkOrderStatus[]> {
        return this.api.getArrayDotNet('WorkOrderStatus', { orderby: 'sort' }, () => new WorkOrderStatus());
    }

    getPurchaseOrderStatuses(settableOnly = false): Promise<PurchaseOrderStatus[]> {
        const params: any = { orderby: 'status' };
        if (settableOnly) {
            params.allowManuallySet_eq = true;
        }
        return this.api.getArrayDotNet('PurchaseOrderStatus', params, () => new PurchaseOrderStatus());
    }

    getWorkOrderTransferReasons(): Promise<WorkOrderTransferReason[]> {
        return this.api.getArrayNode(`WorkOrderTransferReason`, { orderby: 'sort,name' }, () => new WorkOrderTransferReason());
    }

    getObjectQueues(): Promise<ObjectQueue[]> {
        return this.api.getArrayDotNet(`ObjectQueue`, { orderby: 'sort,name' }, () => new ObjectQueue());
    }

    getObjectQueuesBoards(): Promise<ObjectQueuesBoard[]> {
        return this.api.getArrayDotNet(`ObjectQueuesBoard`, { orderBy: 'name' }, () => new ObjectQueuesBoard());
    }


    getQueuesByType(queueType: string, filterQueues: boolean): Promise<ObjectQueue[]> {
        const params: any = { queueType_eq: queueType, orderby: 'sort,name' };
        if (filterQueues) {
            params.hideFromNavigator_eq = false;
        }
        return this.api.getArrayDotNet(`ObjectQueue`, params, () => new ObjectQueue());
    }

    getQueueById(id: string): Promise<ObjectQueue> {
        return this.api.getSingleDotNet(`ObjectQueue`, { id_eq: id }, () => new ObjectQueue());
    }

    getQueueBoardsByType(queueType: string): Promise<ObjectQueuesBoard[]> {
        return this.api.getArrayDotNet('ObjectQueuesBoard', { type_eq: queueType, orderby: 'name' }, () => new ObjectQueuesBoard());
    }

    getQueueBoardById(id: string): Promise<ObjectQueuesBoard> {
        return this.api.getSingleDotNet(`ObjectQueuesBoard/${id}`, null, () => new ObjectQueuesBoard());
    }

    getObjectQueuesByIds(ids: string[]) {
        const idString = ids.join(',');
        return this.api.getArrayDotNet('ObjectQueue', { orderby: 'sort,name', id_contains: idString }, () => new ObjectQueue());
    }

    getObjectQueueById(id: string): Promise<ObjectQueue> {
        return this.api.getSingleDotNet('ObjectQueue', { orderby: 'sort,name', id_eq: id }, () => new ObjectQueue());
    }

    getObjectQueueGroups(): Promise<string[]> {
        return this.api.getArrayDotNet(`ObjectQueue/Distinct`, { properties: 'Grouping', grouping_neq: '{{null}}' });
    }

    deleteQueue(id: string) {
        return this.api.deleteDotNet(`ObjectQueue/${id}`);
    }

    deleteSwimLane(id: string) {
        return this.api.deleteDotNet(`ContractorSwimLane/${id}`);
    }

    unDeleteQueue(id: string) {
        return this.api.unDeleteDotNet(`ObjectQueue/${id}`);
    }

    unDeleteSwimLane(id: string) {
        return this.api.unDeleteDotNet(`ContractorSwimLane/${id}`);
    }

    getRoles(): Promise<Role[]> {
        return this.api.getArrayDotNet(`Role`, { orderby: 'name' });
    }

    getAllGlobalCommands(): Promise<GlobalCommandClient[]> {
        return this.api.getArrayDotNet(`GlobalCommand`, { orderby: 'name' }, () => new GlobalCommandClient());
    }

    getGlobalCommandsForObjectType(objectType: string) {
        return this.api.getArrayDotNet(`GlobalCommand`, { orderby: 'name', objectType_eq: objectType }, () => new GlobalCommandClient());
    }

    saveGlobalCommand(globalCommand: GlobalCommandClient): Promise<any> {
        globalCommand.actions = JSON.stringify(globalCommand.actionsObject);
        return this.api.postIdDotNet(`GlobalCommand`, globalCommand);
    }

    deleteGlobalCommand(globalCommand: GlobalCommandClient): Promise<any> {
        return this.api.deleteDotNet(`GlobalCommand/${globalCommand.id}`);
    }

    unDeleteGlobalCommand(globalCommand: GlobalCommandClient): Promise<any> {
        return this.api.unDeleteDotNet(`GlobalCommand/${globalCommand.id}`);
    }

    getGlobalCommandById(id: string): Promise<GlobalCommandClient> {
        return this.api.getSingleDotNet(`AnonymousClaimDetail/get-global-command/${id}`, null, () => new GlobalCommandClient());
    }

    saveQueue(queue: ObjectQueue) {
        if (!queue.queueQuery.defaultCommandId) {
            delete queue.queueQuery.defaultCommand;
        }

        const copy: ObjectQueue = JSON.parse(JSON.stringify(queue));

        copy.query = JSON.stringify(queue.queueQuery);
        copy.roles = JSON.stringify(queue.rolesObject);
        delete copy.queueQuery;
        delete copy.rolesObject;
        return this.api.postSingleDotNet(`ObjectQueue`, copy);
    }

    saveObjectQueuesBoard(queueBoard: ObjectQueuesBoard) {
        const copy: ObjectQueuesBoard = JSON.parse(JSON.stringify(queueBoard));
        copy.configuration = JSON.stringify(queueBoard.queueQuery);
        copy.roles = JSON.stringify(queueBoard.rolesObject);
        delete copy.queueQuery;
        delete copy.rolesObject;

        return this.api.postSingleDotNet(`ObjectQueuesBoard`, copy);
    }

    saveContractorSwimLane(swimLane: ContractorSwimLane) {
        const copy: ContractorSwimLane = JSON.parse(JSON.stringify(swimLane));

        copy.configuration = JSON.stringify(swimLane.queueQuery);
        copy.roles = JSON.stringify(swimLane.rolesObject);
        delete copy.queueQuery;
        delete copy.rolesObject;

        return this.api.postSingleDotNet(`ContractorSwimLane`, copy);
    }

    saveTags(tagsToAdd: Tag[], tagsToDelete: Tag[]) {
        const promises = [];
        if (tagsToAdd) {
            tagsToAdd.forEach(tag => {
                promises.push(this.api.postArrayDotNet('Tag', tag));

            })
        }
        if (tagsToDelete) {
            tagsToDelete.forEach(tag => {
                promises.push(this.api.deleteDotNet(`Tag/${tag.id}`));
            });
        }

        return Promise.all(promises);
    }

    unDeleteTag(tag: Tag) {
        return this.api.unDeleteDotNet(`Tag/${tag.id}`);
    }

    getTag(tagId: string): Promise<Tag> {
        return this.api.getSingleNode(`Tag`, { id_eq: tagId });
    }

    getAllTags(): Promise<Tag[]> {
        return this.api.getArrayDotNet(`Tag`, { orderby: 'name' });
    }

    getTagMetrics(tagId: string) {
        return this.api.getSingleNode(`TagMetrics/${tagId}`, null, () => new TagMetrics());
    }

    getTagsByType(getWorkOrder: boolean, getPolicy: boolean, getEntity: boolean, getTaskTags: boolean, getContractorInvoiceTags: boolean,
        getPurchaseOrderTags: boolean, getInvoiceTags: boolean, getCustomTableTags: boolean = false, viewMaintenanceServiceTags: boolean = false): Promise<Tag[]> {
        let where = '';
        if (getWorkOrder) {
            where = 'AppliesToWorkOrder=true';
        }
        if (getPolicy) {
            if (where) {
                where += ' OR ';
            }
            where += 'AppliesToPolicy=true';
        }
        if (getEntity) {
            if (where) {
                where += ' OR ';
            }
            where += 'AppliesToEntity=true';
        }
        if (getTaskTags) {
            if (where) {
                where += ' OR ';
            }
            where += 'AppliesToTask=true';
        }
        if (getContractorInvoiceTags) {
            if (where) {
                where += ' OR ';
            }
            where += 'AppliesToContractorInvoice=true';
        }
        if (getPurchaseOrderTags) {
            if (where) {
                where += ' OR ';
            }
            where += 'AppliesToPurchaseOrder=true';
        }
        if (getInvoiceTags) {
            if (where) {
                where += ' OR ';
            }
            where += 'AppliesToInvoice=true';
        }
        if (getCustomTableTags) {
            if (where) {
                where += ' OR ';
            }
            where += 'AppliesToCustomTable=true';
        }
        if (viewMaintenanceServiceTags) {
            if (where) {
                where += ' OR ';
            }
            where += 'AppliesToMaintenanceService=true';

        }

        const params = { orderby: 'name', where };

        return this.api.getArrayDotNet('Tag', params, () => new Tag());
    }

    deleteWorkOrderStatus(id: string) {
        return this.api.deleteDotNet(`WorkOrderStatus/${id}`);
    }

    unDeleteWorkOrderStatus(id: string) {
        return this.api.unDeleteDotNet(`WorkOrderStatus/${id}`);
    }

    saveWorkOrderStatus(status: WorkOrderStatus) {
        return this.api.postSingleDotNet(`WorkOrderStatus`, status);
    }

    updateBIReportGroup_sort(group: BIReportGroup, sort: number) {
        return this.api.patchNode(`BIReportGroup/${group.id}`, { sort: sort });
    }

    updateBIReport_GroupId(report: BIReport, biReportGroupId: string, sort: number) {
        return this.api.patchNode(`BIReport/${report.id}`, { biReportGroupId: biReportGroupId, sort: sort });
    }

    updateBIReportGroupIds(reports: BIReport[], biReportGroupId: string) {
        reports.forEach(report => {
            return this.api.patchNode(`BIReport/${report.id}`, { biReportGroupId: biReportGroupId });
        });
    }

    updateBIReportGroupName(group: BIReportGroup) {
        return this.api.patchNode(`BIReportGroup/${group.id}`, { name: group.name });
    }

    updateBIReportSort(report: BIReport) {
        return this.api.patchNode(`BIReport/${report.id}`, { sort: report.sort });
    }

    updateBIReportName(report: BIReport) {
        return this.api.patchNode(`BIReport/${report.id}`, { name: report.name, reportKey: report.reportKey });
    }

    updateBIReportIcon(report: BIReport) {
        return this.api.patchNode(`BIReport/${report.id}`, { iconClass: report.iconClass });
    }

    updateWorkOrderStatusSort(status: WorkOrderStatus) {
        return this.api.patchDotNet(`WorkOrderStatus/${status.id}`, { sort: status.sort });
    }

    updateAuthorizationRuleSort(rule: AuthorizationRule) {
        return this.api.patchNode(`AuthorizationRule/${rule.id}`, { sort: rule.sort });
    }

    updateWorkOrderItemSort(workOrderItem: WorkOrderItemClient) {
        return this.api.patchDotNet(`WorkOrderItem/${workOrderItem.id}`, { sort: workOrderItem.sort });
    }

    updateTradeCategorySort(tradeCategory: TradeCategory) {
        return this.api.patchNode(`TradeCategory/${tradeCategory.id}`, { sort: tradeCategory.sort });
    }

    updateSalesItemSort(salesItem: SalesItemClient) {
        return this.api.patchDotNet(`SalesItem/${salesItem.id}`, { sort: salesItem.sort });
    }

    updateObjectQueueSort(queue: ObjectQueue) {
        return this.api.patchDotNet(`ObjectQueue/${queue.id}`, { sort: queue.sort });
    }

    updateSwimLaneSort(swimLane: ContractorSwimLane) {
        return this.api.patchDotNet(`ContractorSwimLane/${swimLane.id}`, { sort: swimLane.sort });
    }

    deleteClosingItem(closingItem: Item): Promise<any> {
        return this.api.deleteDotNet(`item/${closingItem.id}`);
    }

    undoDeleteClosingItem(closingItem: Item): Promise<any> {
        return this.api.unDeleteDotNet(`item/${closingItem.id}`);
    }

    saveClosingItem(closingItem: Item): Promise<any> {
        return this.api.postSingleDotNet(`item`, closingItem);
    }

    public deleteSalesItem(salesItemId: string) {
        return this.api.deleteDotNet('SalesItem/' + salesItemId);
    }

    public unDeleteSalesItem(salesItemId: string) {
        return this.api.unDeleteDotNet(`SalesItem/${salesItemId}`);
    }

    getUnusedClosingItems(): Promise<Item[]> {
        return this.api.getArrayDotNet(`ClosingItem/unused-closing-items`, null, () => new Item());
    }

    getItem(id: string): Promise<Item> {
        return this.api.getSingleDotNet(`Item/${id}`, null, () => new Item());
    }

    getCompanyInfo(): Promise<Entity> {

        return new Promise<Entity>((resolve, reject) => {
            this.api.getArrayDotNet('entity?type_eq=Company', null, () => new Entity()).then(entities => {
                if (entities && entities.length > 0) {
                    this.entityApi.getFullEntity(entities[0].id).then(entity => resolve(entity));
                } else {
                    const entity = new Entity();
                    entity.type = 'Company';
                    resolve(entity);
                }
            });
        });
    }

    getCompanyStates(): Promise<Entity[]> {
        return this.api.getArrayDotNet('entity?type_eq=CompanyState&orderby=name', null, () => new Entity());
    }

    getAllBrands(): Promise<Brand[]> {
        return this.api.getArrayNode('Brand', { orderby: 'Name' }, () => new Brand());
    }

    saveBrand(brand: Brand): Promise<string> {
        if (!brand.id) {
            brand.id = UtilitiesService.newid();
        }

        return this.api.postIdNode('Brand', brand);
    }

    deleteBrand(brand: Brand) {
        return this.api.deleteNode('Brand/' + brand.id);
    }

    undoDeleteBrand(brand: Brand) {
        return this.api.unDeleteNode('Brand/' + brand.id);
    }

    getStateRegions(stateId: string): Promise<Entity[]> {
        return this.api.getArrayDotNet('entity', {
            type_eq: 'StateRegion',
            parentid_eq: stateId,
            orderby: 'name'
        }, () => new Entity());
    }

    getSubRegions(regionId: string): Promise<Entity[]> {
        return this.api.getArrayDotNet('entity', {
            parentid_eq: regionId,
            orderby: 'name'
        }, () => new Entity());
    }

    getAllRegions(): Promise<Entity[]> {
        return this.api.getArrayDotNet('entity', {
            type_eq: 'StateRegion',
            orderby: 'name'
        }, () => new Entity());
    }

    getStateAndRegionGrouped(): Promise<any[]> {
        return new Promise<any[]>((resolve, reject) => {
            this.getCompanyStates().then(states => {
                this.getAllRegions().then(regions => {
                    const returnValue = [];

                    states.forEach(state => {
                        const childRegions = regions.filter(i => i.parentId === state.id);

                        returnValue.push({ state, regions: childRegions });
                    });

                    resolve(returnValue);
                });
            });
        });
    }



    deletePromotionCode(promotionCodeId: string) {
        return this.api.deleteDotNet('PromotionCode/' + promotionCodeId);
    }

    undoPromotionCodeDelete(promotionCodeId: string) {
        return this.api.unDeleteNode('PromotionCode/' + promotionCodeId);
    }


}